import React, {useEffect} from 'react';
import "./SiteNotFound.css";

function SiteNotFound() {

    useEffect(() => {
        redirectToHome();
    }, []);

    function redirectToHome(){
        setTimeout(()=>{
            window.history.pushState({}, "", "/")
            window.location.reload()
        }, 10)
    }

 return (
  <>
      <div id={"SITENOTFOUNDTEXT"}>
          <p>SITE NOT FOUND 404</p>
          <a href="/">Redirect to Home</a>
      </div>
  </>
 );}

export default SiteNotFound;