import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import "./Index.scss";
import {generateUUID} from "../../../utils/generateUUID";

function Index() {

    const [linkButtons] = useState([{
        "link": "https://discord.quantumrealms.de/",
        "icon": "bi-discord"
    },{
        "link": "https://instagram.quantumrealms.de/",
        "icon": "bi-instagram"
    },{
        "link": "https://tiktok.quantumrealms.de/",
        "icon": "bi-tiktok"
    },{
        "link": "https://youtube.quantumrealms.de/",
        "icon": "bi-youtube"
    },{
        "link": "https://store.quantumrealms.de/",
        "icon": "bi-coin"
    },])

    const config = {
        serverInfo: {
            serverIp: "quantumrealms.de", /*Server IP (if you want to add online user counter, you must have true the enable-status and enable-query of server.properties)*/
            discordServerID: "1249905903369719879" /*Your server ID (if you want to add online user counter, you must have enabled Discord server widget)*/
        }
    }

    const {} = useTranslation();

    const [, setDiscordActiveUser] = useState(0);
    const [minecraftServer, setMinecraftServer] = useState(0);

    const getDiscordOnlineUsers = async () => {
        try {
            const discordServerId = config.serverInfo.discordServerID;

            const apiWidgetUrl = `https://discord.com/api/guilds/${discordServerId}/widget.json`;
            let response = await fetch(apiWidgetUrl);
            let data = await response.json();

            if (!data.presence_count) return "None";
            else return (await data.presence_count);
        } catch (e) {
            return "-";
        }
    };

    const getMinecraftOnlinePlayer = async () => {
        try {
            const serverIp = config.serverInfo.serverIp;

            const apiUrl = `https://api.mcsrvstat.us/2/${serverIp}`;
            let response = await fetch(apiUrl);
            return await response.json();
        } catch (e) {
            return "-";
        }
    };

    /*IP copy only works if you have HTTPS on your website*/
    const copyIp = () => {
        const copyIpAlert = document.querySelector(".ip-copied");
        try {
            navigator.clipboard.writeText(config.serverInfo.serverIp);

            copyIpAlert.classList.add("active");

            setTimeout(() => {
                copyIpAlert.classList.remove("active");
            }, 1000);
        } catch (e) {
            copyIpAlert.innerHTML = "An error has occurred!";
            copyIpAlert.classList.add("active");
            copyIpAlert.classList.add("error");

            setTimeout(() => {
                copyIpAlert.classList.remove("active");
                copyIpAlert.classList.remove("error");
            }, 1000);
        }
    };

    useEffect(() => {
        getDiscordOnlineUsers().then(x => {
            setDiscordActiveUser(x);
        })
        getMinecraftOnlinePlayer().then(x => {
            setMinecraftServer(x);
        })
    }, []);

    return (
        <div id="Index" className="container">
            <div className={"background-video opacity-25"}>
                <video muted autoPlay loop>
                    <source src="https://cloud.uxnetwork.eu/index.php/s/xaALotcyGQAMN4G/download" type='video/mp4'/>
                </video>
            </div>
            <section className="text-center d-flex align-items-center justify-content-evenly custom-section section-start">
                <div className="info-box text text-box">
                    <p>QuantumRealms.de</p>

                    <h1>QuantumRealms</h1>
                    <p className={"fw-normal"}>QuantumRealms ist der ultimative Minecraft-Server für Citybuild und
                        Community. Entdecke die Freiheit des Bauens und gestalte mit uns die Zukunft unseres
                        Minecraft-Servers!</p>
                    <div className="buttons">
                        <button className="copy-ip" onClick={copyIp}>IP kopieren</button>
                        <div className="alert alert-success align-items-center ip-copied" role="alert">
                            <i className="bi bi-check-circle-fill"></i>Copied IP
                        </div>
                        {
                            linkButtons.map((linkButton) => (
                                <a href={linkButton.link} target={"_blank"} key={generateUUID()}>
                                    <button className="join"><i className={"bi " + linkButton.icon}></i></button>
                                </a>
                            ))
                        }
                    </div>
                </div>
                <div>
                    <img src="/logo512.png" alt="logo"/>
                    <div className="stats">
                        {
                            minecraftServer &&
                            <>
                                <div className="stat">
                                    <a className="texts" href={"#"}>
                                        <div className="icon">
                                            <img
                                                src={minecraftServer.icon || "https://cdn.icon-icons.com/icons2/2699/PNG/512/minecraft_logo_icon_168974.png"}
                                                alt={minecraftServer.hostname} style={{
                                                filter: minecraftServer.online ? 'none' : 'saturate(0)',
                                            }}></img>
                                        </div>
                                        <div className="text">
                                            <h5 className="stat-title">QuantumRealms.de</h5>
                                            <p>
                                                <span>{minecraftServer.players.online}/{minecraftServer.players.max}</span> Spieler
                                                online</p>
                                        </div>
                                    </a>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </section>
            <section className="text-center d-flex flex-column gap-4 z-2 align-items-center justify-content-evenly custom-section section-modes">
                <h1>Unsere Spielmodi:</h1>
                <div className={"w-50 d-flex flex-row justify-content-around align-items-center"}>
                    <a className="card w-25" href={"/citybuild"}>
                        <img src="https://cloud.uxnetwork.eu/index.php/s/M2DwZEgfcGnj8iN/download" className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <p className="card-text">CityBuild</p>
                        </div>
                    </a>
                </div>
            </section>
        </div>
    );
}

export default Index;
