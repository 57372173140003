import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './common-styles.scss';
import './common-styles-responsive.css';
import App from './App';

import './utils/i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);