import "./Footer.css";

function Footer() {
    return (
        <div id="Footer">
            <div className={"FooterContent"}>
                <div>

                </div>
                <div>

                </div>
            </div>
            <div className={"FooterCopyright"}>
                Copyright © {new Date().getFullYear()} QuantumRealms MBUXN - All rights reserved
            </div>
        </div>
    );
}

export default Footer;