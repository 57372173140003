import React, {useEffect, useState} from 'react';
import './Team.scss';

const Team = () => {
    const Team = {
        Owner: {
            title: "Inhaber von Quantumrealms.de",
            members:
                [{
                    name: 'Uflax',
                    minecraftUsername: 'einfachUflax'
                }]
        },
        Administrator: {
            title: "Teamleitung und Administration von Projekten",
            members:
                [{
                    name: 'Veve',
                    minecraftUsername: 'EinfachVeve'
                }]
        },
        Developer: {
            title: "Zuständig für Updates und umsetzung von Projektideen",
            members:
                [{
                    name: 'Joniwoch',
                    minecraftUsername: 'joniwoch'
                }, {
                    name: 'Jan',
                    minecraftUsername: 'Jan2808'
                }, {
                    name: 'Vielleicht ja du!<br /><a href="https://discord.quantumrealms.de">Bewirb dich jetzt!</a>',
                    minecraftUsername: '?'
                }]
        },
        Moderator: {
            title: "Zuständig für die Einhaltung unserer Spielregeln",
            members:
                [{
                    name: 'Ich_Esse_Burger',
                    minecraftUsername: 'Ich_Esse_Burger'
                }]
        },
        Supporter: {
            title: "Bei Fragen und Anregungen immer erreichbar",
            members:
                [{
                    name: 'DJSunny2000',
                    minecraftUsername: 'DJSunny2000'
                }]
        }
    };
    const [skins, setSkins] = useState({});

    const getUuidByUsername = async (username) => {
        try {
            const usernameToUuidApi = `https://api.minetools.eu/uuid/${username}`;
            let response = await fetch(usernameToUuidApi);
            let data = await response.json();

            return data.id;
        } catch (e) {
            return "None";
        }
    }

    const getSkinByUuid = async (username) => {
        try {
            const skinByUuidApi = `https://visage.surgeplay.com/bust/512/${await getUuidByUsername(username)}`;
            let response = await fetch(skinByUuidApi);

            if (response.status === 400) return `https://visage.surgeplay.com/bust/512/ec561538f3fd461daff5086b22154bce`;
            else return skinByUuidApi;
        } catch (e) {
            return "None";
        }
    }

    const loadSkins = async () => {
        const newSkins = {};
        for (const rank in Team) {
            for (const member of Team[rank].members) {
                newSkins[member.minecraftUsername] = await getSkinByUuid(member.minecraftUsername);
            }
        }
        setSkins(newSkins);
    };

    useEffect(() => {
        loadSkins().then(() => {
            console.log("RELOADED SKINS")
        });
    }, []);

    return (
        <div id={"Team"}>
            <div className={"team-backdropFilter"}>
                <div className="team-overview">
                    <h1 className={"text-center"}>Unser Team</h1>
                    <p className={"text-center"}>Die Köpfe hinter QuantumRealms.de</p>
                    {Object.keys(Team).map((rank) => (
                        <div key={rank} className="team-rank">
                            <h4>{rank}</h4>
                            <p>{Team[rank].title}</p> {/* Displaying the title for each rank */}
                            <ul>
                                {/* Ensure that Team[rank].members is an array before mapping */}
                                {Array.isArray(Team[rank].members) &&
                                    Team[rank].members.map((member, index) => (
                                        <li key={index}>
                                            <div>
                                                {/* Check if minecraftUsername exists in skins before rendering */}
                                                {member.minecraftUsername && skins[member.minecraftUsername] && (
                                                    <img src={skins[member.minecraftUsername]} alt="playerskin"/>
                                                )}
                                            </div>
                                            <div>
                                                <h5 dangerouslySetInnerHTML={{ __html: member.name }}></h5>
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    );
};

export default Team;